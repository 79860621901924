import { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import dayjs from 'dayjs';
import { FormControl, Select, MenuItem, InputLabel, Skeleton } from '@mui/material';

import ResourceType from './graphs/ResourceType';
import ScoreTable from './graphs/ScoreTable';
import PersonalityGraph from './graphs/PersonalityGraph';
import ScoreDistribution from './graphs/ScoreDistribution';

const Overview = () => {
  const [orgSelected, setOrgSelected] = useState('');
  const organisations = useStoreState((state) => state.organisations);
  const overviewMetrics = useStoreState((state) => state.overview.overviewMetrics);
  const getOverviewMetrics = useStoreActions((actions) => actions.overview.getOverviewMetrics);

  useEffect(() => {
    if (orgSelected) {
      getOverviewMetrics(orgSelected);
    }
  }, [orgSelected]);

  useEffect(() => {
    setOrgSelected(organisations?.[0]?.id);
  }, [organisations]);

  return (
    <div className="my-2 vh-100">
      <FormControl fullWidth style={{ maxWidth: '200px' }} className="my-2" size="small">
        <InputLabel id="org">Organisation</InputLabel>
        <Select
          labelId="org"
          label="Select organisation"
          value={orgSelected}
          onChange={(e) => setOrgSelected(e.target.value)}>
          {organisations?.map((org) => (
            <MenuItem key={org.id} value={org.id}>
              {org.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {!overviewMetrics ||
      (!overviewMetrics?.uploadMetrics?.length && !overviewMetrics.resourceTypeMetrics?.length) ? (
        <div className="container-fluid px-4 my-4">
          <div className="row g-4">
            <div className="col-8">
              <Skeleton variant="rectangular" height={300} />
            </div>
            <div className="col-4">
              <Skeleton variant="rectangular" height={300} />
            </div>
            <div className="col-6">
              <Skeleton variant="rectangular" height={300} />
            </div>
            <div className="col-6">
              <Skeleton variant="rectangular" height={300} />
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid px-3 my-4">
          <div className="row g-4">
            <div
              className="col-lg-8 p-3"
              style={{
                boxShadow: 'rgb(227, 227, 227) 7px 7px 21px, rgb(255, 255, 255) -7px -7px 21px'
              }}>
              <p className="fs-6 fw-medium">Resources uploaded</p>
              <hr />
              <div style={{ fontSize: '0.85em' }} className="my-3">
                Total uploads:{' '}
                {overviewMetrics?.uploadMetrics.reduce((sum, item) => sum + item.uploads, 0)}
              </div>
              <ResponsiveContainer width="100%" height="75%">
                <LineChart
                  width={500}
                  height={300}
                  data={overviewMetrics?.uploadMetrics}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 0,
                    bottom: 5
                  }}>
                  <XAxis
                    strokeOpacity={0}
                    dataKey="date"
                    tick={({ x, y, payload }) => (
                      <text fontSize={12} x={x} y={y + 15} fill="#666" textAnchor="middle">
                        {dayjs(payload.value).format('DD MMM')}
                      </text>
                    )}
                  />
                  <YAxis
                    tick={({ x, y, payload }) => (
                      <text fontSize={12} x={x - 10} y={y + 5} fill="#666" textAnchor="middle">
                        {payload.value}
                      </text>
                    )}
                    strokeOpacity={0}
                    dataKey="uploads"
                  />
                  <Tooltip
                    labelFormatter={(value) => `Date: ${dayjs(value).format('DD-MM-YYYY')}`}
                  />
                  <Line type="monotone" strokeWidth={2} dataKey="uploads" stroke="#0683fe" />
                  <CartesianGrid strokeDasharray="3 3" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div
              className="col-lg-4 p-3"
              style={{
                boxShadow: 'rgb(227, 227, 227) 7px 7px 21px, rgb(255, 255, 255) -7px -7px 21px'
              }}>
              <p className="fs-6 fw-medium">Resources distribution</p>
              <hr />
              <div className="w-100 h-100">
                <ResourceType data={overviewMetrics.resourceTypeMetrics} />
              </div>
            </div>
            <div
              className="col-lg-6 p-3"
              style={{
                boxShadow: 'rgb(227, 227, 227) 7px 7px 21px, rgb(255, 255, 255) -7px -7px 21px'
              }}>
              <p className="fs-6 fw-medium">Interview total score distribution</p>
              <hr />
              <ScoreDistribution data={overviewMetrics?.scoreDistribution} />
            </div>
            <div
              className="col-lg-6 p-3"
              style={{
                boxShadow: 'rgb(227, 227, 227) 7px 7px 21px, rgb(255, 255, 255) -7px -7px 21px'
              }}>
              <p className="fs-6 fw-medium">Interview parameters summary statistics</p>
              <hr />
              <ScoreTable data={overviewMetrics?.prepsyMetric} />
            </div>
            <div
              className="col-lg-4 p-3"
              style={{
                boxShadow: 'rgb(227, 227, 227) 7px 7px 21px, rgb(255, 255, 255) -7px -7px 21px'
              }}>
              <p className="fs-6 fw-medium">BIG 5 personality - Extraversion</p>
              <hr />
              <div className="w-100 h-100">
                <PersonalityGraph data={overviewMetrics?.personalityMetrics.extraversion} />
              </div>
            </div>
            <div
              className="col-lg-4 p-3"
              style={{
                boxShadow: 'rgb(227, 227, 227) 7px 7px 21px, rgb(255, 255, 255) -7px -7px 21px'
              }}>
              <p className="fs-6 fw-medium">BIG 5 personality - Agreeableness</p>
              <hr />
              <div className="w-100 h-100">
                <PersonalityGraph data={overviewMetrics?.personalityMetrics.agreeableness} />
              </div>
            </div>
            <div
              className="col-lg-4 p-3"
              style={{
                boxShadow: 'rgb(227, 227, 227) 7px 7px 21px, rgb(255, 255, 255) -7px -7px 21px'
              }}>
              <p className="fs-6 fw-medium">BIG 5 personality - Conscientiousness</p>
              <hr />
              <div className="w-100 h-100">
                <PersonalityGraph data={overviewMetrics?.personalityMetrics.conscientiousness} />
              </div>
            </div>
            <div
              className="col-lg-4 p-3"
              style={{
                boxShadow: 'rgb(227, 227, 227) 7px 7px 21px, rgb(255, 255, 255) -7px -7px 21px'
              }}>
              <p className="fs-6 fw-medium">BIG 5 personality - Neuroticism</p>
              <hr />
              <div className="w-100 h-100">
                <PersonalityGraph data={overviewMetrics?.personalityMetrics.neuroticism} />
              </div>
            </div>
            <div
              className="col-lg-4 p-3"
              style={{
                boxShadow: 'rgb(227, 227, 227) 7px 7px 21px, rgb(255, 255, 255) -7px -7px 21px'
              }}>
              <p className="fs-6 fw-medium">BIG 5 personality - Openness</p>
              <hr />
              <div className="w-100 h-100">
                <PersonalityGraph data={overviewMetrics?.personalityMetrics.openness} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Overview;
