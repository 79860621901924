import { getAuth, updateProfile } from 'firebase/auth';
import { addDoc, collection, doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { getFileExtension } from '../../../helpers/util.helps';

export const getProfileData = async () => {
  const auth = getAuth();
  const { uid } = auth.currentUser;
  const db = getFirestore();
  const dataRef = doc(db, 'users', uid);
  const data = await getDoc(dataRef);
  return data.data();
};

export const saveProfileData = async (name, cropData) => {
  const auth = getAuth();
  const user = auth.currentUser;
  const db = getFirestore();
  const storage = getStorage();
  const signRef = cropData
    ? ref(storage, `/users/${user.uid}/signature.${getFileExtension(cropData.file.name)}`)
    : null;
  await Promise.all([
    updateProfile(user, {
      displayName: name
    }),
    signRef && uploadBytes(signRef, cropData.file)
  ]);
  const signUrl = signRef && (await getDownloadURL(signRef));
  await Promise.all([
    updateDoc(doc(db, 'users', user.uid), {
      name,
      signUrl
    }),
    signUrl &&
      addDoc(collection(db, 'crop_data'), {
        uid: user.uid,
        name: 'signature',
        cropData: cropData.cropData,
        newImageUrl: signUrl
      })
  ]);
};
