import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell, Legend, Tooltip } from 'recharts';

const PersonalityGraph = ({ data }) => {
  const colors = ['#0683fe', '#34c759', '#ffcc00', '#ff6600'];

  return (
    <ResponsiveContainer width="100%" height="84%">
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={40}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          nameKey="name">
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Legend
          content={(payload) => (
            <div>
              {payload.payload.map((item) => (
                <div key={item.value} className="d-flex justify-content-center align-items-center">
                  <div
                    style={{
                      height: '12px',
                      width: '12px',
                      backgroundColor: item.color,
                      borderRadius: '12px'
                    }}
                  />
                  <p
                    style={{ fontSize: '0.85em' }}
                    className="m-1 mx-2 text-secondary fw-medium flex-grow-1">
                    {item.value}
                  </p>
                  <p style={{ fontSize: '0.85em' }} className="my-1 text-secondary fw-medium">
                    {item.payload.value}%
                  </p>
                </div>
              ))}
            </div>
          )}
        />
        <Tooltip formatter={(value) => `${value}%`} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PersonalityGraph;
