import { getDoc, getFirestore, doc, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

import { fieldsEnum } from '../../helpers/constant.helper';
import { getSearchKey } from '../../helpers/api.helper';
import { toast } from 'react-hot-toast';

const db = getFirestore();

export const getUserData = async (uid) => {
  const userData = await getDoc(doc(db, `users/${uid}`)).then((res) => res.data());
  return userData;
};

export const getOrganisations = async (orgArr) => {
  const orgs = await Promise.all(
    orgArr.map((orgId) =>
      getDoc(doc(db, 'organisations', orgId)).then((doc) => ({ id: doc.id, ...doc.data() }))
    )
  );
  return orgs;
};

export const getResourceTypes = async (resourceIds) => {
  const types = await Promise.all(
    resourceIds.map((typeId) =>
      getDoc(doc(db, 'resource_types', typeId)).then((doc) => ({ id: doc.id, ...doc.data() }))
    )
  );
  return types;
};

export const getPublicSearchKey = async () => {
  const key = await getSearchKey()
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 'fail') {
        toast.error('Failed to get search key');
      } else {
        return res.data.searchKey;
      }
    });
  return key;
};

export const uploadResource = async (orgId, identifiers, resources, resourceType, prevResource) => {
  const auth = getAuth();
  const userId = auth.currentUser?.uid;
  const userEmail = auth.currentUser?.email;
  const resourcesData = [
    ...resources.map((resource) => ({
      id: resource.id,
      value: resource.value
    }))
  ];
  const identifiersData = [
    ...identifiers.map((field) => ({
      id: field.id,
      value: field.value
    }))
  ];
  await Promise.all(
    resources
      .filter(
        (resource) => resource.fieldType === fieldsEnum.FILE && typeof resource.value !== 'string'
      )
      .map((resource) =>
        uploadFile(resource.value, orgId).then((fileUrl) => {
          resourcesData.forEach((item, index) => {
            if (item.id === resource.id) {
              resourcesData[index].value = fileUrl;
            }
          });
        })
      )
  );
  const db = getFirestore();
  const resourcesRef = collection(db, 'resources');
  await addDoc(resourcesRef, {
    org_id: orgId,
    identifiers: identifiersData,
    resources: resourcesData,
    resource_type: resourceType,
    creation_timestamp: prevResource ? prevResource.creation_timestamp : Date.now(),
    created_by: userId,
    created_by_email: userEmail,
    ...(prevResource ? { updated_timestamp: Date.now(), prev_update: prevResource.id } : {})
  });
};

const uploadFile = async (file, orgId) => {
  const storage = getStorage();
  const auth = getAuth();
  const userId = auth.currentUser?.uid;
  const fileName = `${file.name.split('.')[0]}_${Date.now()}.${file.name.split('.').pop()}`;
  const fileRef = ref(storage, `organisations/${orgId}/${userId}/${fileName}`);
  await uploadBytes(fileRef, file);
  const fileUrl = await getDownloadURL(fileRef);
  return fileUrl;
};
