import { useState, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import { useStoreState } from 'easy-peasy';
import UploadPic from './UploadPic';
import { getProfileData, saveProfileData } from './profile.service';
import { toast } from 'react-hot-toast';

const Profile = () => {
  const user = useStoreState((state) => state.user);
  const [name, setName] = useState(user.displayName || '');
  const [signUrl, setSignUrl] = useState('');
  const [signCropData, setSignCropData] = useState();

  const setCroppedImage = () => (cropData) => {
    setSignCropData(cropData);
  };

  const handleSave = (e) => {
    e.preventDefault();
    toast.promise(saveProfileData(name, signCropData), {
      loading: 'Saving data',
      success: 'Saved successfully',
      error: 'Failed to save'
    });
  };

  useEffect(() => {
    getProfileData().then((data) => {
      setSignUrl(data.signUrl);
    });
  }, []);

  return (
    <form onSubmit={handleSave}>
      <UploadPic
        name="Profile picture"
        helperText={[
          'Adjust the image to ensure the aspect ratio is 1:1. This helps improve your website performance like load times.',
          'Tip: Use a professional picture. It helps in establishing credibility with the client.'
        ]}
        aspectRatioWidth={16}
        aspectRatioHeight={9}
        maxWidth="200px"
        setCropData={setCroppedImage('profilePic')}
        imageUrl={signUrl}
      />
      <div className="my-4 margin-bottom--md">
        <TextField
          size="small"
          value={name}
          label="Full name"
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};

export default Profile;
