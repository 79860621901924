import React, { useState } from 'react';
import { NavLink, Outlet, useMatch } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import Header from '../shared/Header';
import Sidebar from '../shared/sidebar/Sidebar';

const Dashboard = () => {
  const [menu, setMenu] = useState(false);
  const isResources = useMatch('dashboard/resources');
  const [tab, setTab] = useState(isResources ? 0 : 1);

  return (
    <div className="container-fluid h-100">
      <Header setMenu={setMenu} />
      <div className="row" style={{ minHeight: 'calc(100vh - 70px)' }}>
        <div className="col-auto">
          <div className="d-flex flex-column pt-2">
            <Sidebar
              sidebar={menu}
              handleSidebar={() => {
                setMenu(!menu);
              }}
            />
          </div>
        </div>
        <div className="col">
          <Outlet />
        </div>
        {window.innerWidth > 960 ? null : (
          <div className="fixed-bottom text-bg-light">
            <Tabs
              value={tab}
              onChange={(e, newValue) => {
                setTab(newValue);
              }}
              textColor="primary"
              variant="fullWidth"
              centered>
              <Tab
                icon={<CloudCircleIcon />}
                LinkComponent={NavLink}
                to="/dashboard/resources"
                sx={{ textTransform: 'none' }}
                label="Resources"
                value={0}
              />
              <Tab
                icon={<UploadFileIcon />}
                LinkComponent={NavLink}
                to="/dashboard/upload-resource"
                sx={{ textTransform: 'none' }}
                label="Upload resource"
                value={1}
              />
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
