import { useState } from 'react';
import { useInstantSearch } from 'react-instantsearch-hooks-web';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useStoreState, useStoreActions } from 'easy-peasy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/material';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import ResourceItem from './ResourceItem';
import { getAdditionalResources } from './resources.service';
import { toast } from 'react-hot-toast';
import { getFilename } from '../../../helpers/util.helps';

const AllResources = ({ orgIds }) => {
  const { results } = useInstantSearch();
  const resources = useStoreState((state) => state.resources);
  const resourcesLastDoc = useStoreState((state) => state.resourcesLastDoc);
  const addAdditionalResources = useStoreActions((actions) => actions.addAdditionalResources);
  const hasMoreResources = useStoreState((state) => state.hasMoreResources);
  const setResourcesLastDoc = useStoreActions((actions) => actions.setResourcesLastDoc);
  const setHasMoreResources = useStoreActions((actions) => actions.setHasMoreResources);
  const clearSelection = useStoreActions((actions) => actions.clearSelection);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectEnabled, setSelectEnabled] = useState(false);
  const optionsOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setSelect = (enabled) => {
    setSelectEnabled(enabled);
    handleClose();
    if (!enabled) {
      clearSelection();
    }
  };

  const downloadSelected = () => {
    const selectedResources = resources
      .filter((resource) => resource.selected)
      .map((resource) => resource.resources);
    if (!selectedResources.length) {
      toast.error('Please select resources to download');
      return;
    }
    toast.promise(
      Promise.all(
        selectedResources.map((resource) => {
          const primaryField = resource.find((field) => field.primary);
          return fetch(primaryField.value)
            .then((response) => response.blob())
            .then((blob) => ({
              name: getFilename(primaryField.value),
              blob
            }));
        })
      ).then((files) => {
        const zip = new JSZip();
        files.map((file) => {
          zip.file(file.name, file.blob);
        });
        zip.generateAsync({ type: 'blob' }).then((blob) => {
          saveAs(blob, 'airbase-assets.zip');
          handleClose();
          setSelect(false);
        });
      }),
      {
        success: 'Files downloaded',
        loading: 'Downloading files',
        error: 'Failed to download files'
      }
    );
  };

  const getAdditionalData = () => {
    getAdditionalResources(orgIds, resourcesLastDoc).then((res) => {
      addAdditionalResources(res.data);
      setResourcesLastDoc(res.lastDoc);
      setHasMoreResources(res.hasMore);
    });
  };

  return (
    <div>
      <div className="container-fluid my-2">
        <div style={selectEnabled ? { marginLeft: '20px' } : {}} className="row border-bottom pb-2">
          <Box className="col">Identifier</Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }} className="col">
            Resource
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }} className="col">
            Status
          </Box>
          <Box className="col">
            <div className="d-flex align-item-center justify-content-between">
              Actions
              <MoreVertIcon
                id="more-button"
                aria-controls={optionsOpen ? 'more-options' : undefined}
                aria-haspopup="true"
                aria-expanded={optionsOpen ? 'true' : undefined}
                onClick={handleClick}
              />
              <Menu
                id="more-options"
                anchorEl={anchorEl}
                open={optionsOpen}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'options-button'
                }}>
                {!selectEnabled ? (
                  <MenuItem onClick={() => setSelect(true)}>Select records</MenuItem>
                ) : (
                  <>
                    <MenuItem onClick={() => setSelect(false)}>Cancel selection</MenuItem>
                    <MenuItem onClick={downloadSelected}>Download documents</MenuItem>
                  </>
                )}
              </Menu>
            </div>
          </Box>
        </div>
        {results.query ? (
          <div className="overflow-hidden">
            {results.hits?.map((d) => (
              <ResourceItem item={d} key={d.id} />
            ))}
          </div>
        ) : (
          <InfiniteScroll
            dataLength={resources.length}
            next={getAdditionalData}
            className="overflow-x-hidden"
            hasMore={hasMoreResources}
            loader={<h4 className="h5 text-center">Loading...</h4>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }>
            {resources.map((d) => (
              <ResourceItem selectEnabled={selectEnabled} item={d} key={d.id} />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default AllResources;
