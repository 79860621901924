import React from 'react';

const ScoreTable = ({ data }) => {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Parameters of evaluation</th>
            <th scope="col">Mean</th>
            <th scope="col">S.D.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="fw-medium">
              Problem solving & decision making
            </th>
            <td className="text-primary">{Number(data?.problemSolvingMean).toFixed(1)}</td>
            <td className="text-primary">{Number(data?.problemSolvingSD).toFixed(1)}</td>
          </tr>
          <tr>
            <th scope="row" className="fw-medium">
              Communication
            </th>
            <td className="text-primary">{Number(data?.communicationMean).toFixed(1)}</td>
            <td className="text-primary">{Number(data?.communicationSD).toFixed(1)}</td>
          </tr>
          <tr>
            <th scope="row" className="fw-medium">
              Coping with pressure
            </th>
            <td className="text-primary">{Number(data?.copingPressureMean).toFixed(1)}</td>
            <td className="text-primary">{Number(data?.copingPressureSD).toFixed(1)}</td>
          </tr>
          <tr>
            <th scope="row" className="fw-medium">
              Leadership and teamwork
            </th>
            <td className="text-primary">{Number(data?.leadershipMean).toFixed(1)}</td>
            <td className="text-primary">{Number(data?.leadershipSD).toFixed(1)}</td>
          </tr>
          <tr>
            <th scope="row" className="fw-medium">
              Passion for flying or air traffic controlling
            </th>
            <td className="text-primary">{Number(data?.passionForFlyingMean).toFixed(1)}</td>
            <td className="text-primary">{Number(data?.passionForFlyingSD).toFixed(1)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ScoreTable;
