import { action, thunk } from 'easy-peasy';
import { getDashboardMetrics } from '../../../helpers/api.helper';

const overview = {
  orgSelected: 'PTII4lCc5JkAUmajsnS0',
  overviewMetrics: {},
  setOrg: action((state, payload) => {
    state.orgSelected = payload;
  }),
  setOverviewMetrics: action((state, payload) => {
    state.overviewMetrics = payload;
  }),
  getOverviewMetrics: thunk(async (actions, orgId) => {
    const data = await getDashboardMetrics(orgId).then((res) => res.json());
    actions.setOverviewMetrics(data.data);
  })
};

export default overview;
