import { useState } from 'react';
import { UploadFile } from '@mui/icons-material';
import {
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch
} from '@mui/material';
import {
  fieldsEnum,
  prepsyParametersId,
  prepsyPassionForFlyingId
} from '../../../helpers/constant.helper';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { getFilename } from '../../../helpers/util.helps';

const Fields = ({ fields, updateField }) => {
  const [fileNames, setFileNames] = useState({});
  const handleFileUploads = (id, file) => {
    updateField(id, file);
    setFileNames((names) => ({
      ...names,
      [id]: file.name
    }));
  };

  return (
    <div>
      {fields?.map((field) => (
        <div key={field.name}>
          {field.fieldType === fieldsEnum.TEXT ? (
            <TextField
              id={field.name}
              label={field.name}
              variant="outlined"
              className="my-2"
              size="small"
              fullWidth
              value={field.value ?? ''}
              onChange={(e) => updateField(field.id, e.target.value)}
              style={{ maxWidth: '300px' }}
              required={field.mandatory}
            />
          ) : null}
          {field.fieldType === fieldsEnum.DROPDOWN ? (
            <FormControl
              fullWidth
              style={{ maxWidth: '300px' }}
              className="my-2"
              size="small"
              required={field.mandatory}>
              <InputLabel id="resource-type">{field.name}</InputLabel>
              <Select
                labelId="resource-type"
                id="file-type-select"
                label={field.name}
                value={field.value ?? ''}
                onChange={(e) => updateField(field.id, e.target.value)}>
                {field.options?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
          {field.fieldType === fieldsEnum.NUMBER ? (
            <TextField
              id={field.name}
              label={field.name}
              type="number"
              variant="outlined"
              className="my-2"
              size="small"
              fullWidth
              disabled={
                field.id === prepsyPassionForFlyingId &&
                fields.find((resource) => resource.id === prepsyParametersId)?.value ===
                  '4 parameters'
              }
              title="only number inputs are allowed"
              inputProps={{ min: field.min, max: field.max, step: field.decimal ? 0.1 : 1 }}
              value={field.value ?? undefined}
              onChange={(e) => updateField(field.id, Number(e.target.value))}
              style={{ maxWidth: '300px' }}
              required={field.mandatory}
            />
          ) : null}
          {field.fieldType === fieldsEnum.URL ? (
            <TextField
              id={field.name}
              label={field.name}
              variant="outlined"
              className="my-2"
              size="small"
              fullWidth
              title="Please enter the URL"
              inputProps={{
                inputMode: 'url'
              }}
              value={field.value}
              onChange={(e) => updateField(field.id, e.target.value)}
              style={{ maxWidth: '300px' }}
              required={field.mandatory}
            />
          ) : null}
          {field.fieldType === fieldsEnum.EMAIL ? (
            <TextField
              type="email"
              id={field.name}
              label={field.name}
              variant="outlined"
              size="small"
              className="my-2"
              fullWidth
              value={field.value}
              onChange={(e) => updateField(field.id, e.target.value)}
              style={{ maxWidth: '300px' }}
              required={field.mandatory}
            />
          ) : null}
          {field.fieldType === fieldsEnum.DATE ? (
            <DatePicker
              className="my-2"
              value={field.value ? dayjs(field.value) : null}
              slotProps={{ textField: { size: 'small', style: { width: '300px' } } }}
              format="DD/MM/YYYY"
              label={field.name}
              onChange={(newValue) => {
                updateField(field.id, newValue.valueOf());
              }}
            />
          ) : null}
          {field.fieldType === fieldsEnum.FILE ? (
            <div className="field">
              <TextField
                label={field.name}
                id={`${field.id}-name`}
                variant="outlined"
                fullWidth
                size="small"
                className="my-2"
                style={{ maxWidth: '300px' }}
                disabled
                value={fileNames[field.id] || (field.value ? getFilename(field.value) : '')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <label htmlFor={field.id} style={{ cursor: 'pointer' }}>
                        <UploadFile color="primary" />
                      </label>
                      <input
                        onChange={(e) => handleFileUploads(field.id, e.target.files[0])}
                        type="file"
                        className="d-none"
                        id={field.id}
                      />
                    </InputAdornment>
                  )
                }}
              />
            </div>
          ) : null}
          {field.fieldType === fieldsEnum.RADIO ? (
            <FormControl className="my-2">
              <FormLabel>{field.name}</FormLabel>
              <RadioGroup
                id={field.name}
                name={field.name}
                value={field.value ?? ''}
                onChange={(e) => updateField(field.id, e.target.value)}
                style={{ maxWidth: '300px' }}
                required={field.mandatory}>
                {field.options.map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          ) : null}
          {field.fieldType === fieldsEnum.SWITCH ? (
            <FormControlLabel
              className="my-2"
              control={
                <Switch
                  id={field.name}
                  name={field.name}
                  value={field.value ?? false}
                  onChange={(e) => updateField(field.id, e.target.checked)}
                  style={{ maxWidth: '300px' }}
                  required={field.mandatory}
                />
              }
              label={field.name}
            />
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default Fields;
