/* eslint-disable no-undef */
import { auth } from './firebase.helper';

// const BASE_URL = 'http://127.0.0.1:5001/air-baseline-test/us-central1/airBaseline/';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const makePost = async (url, data, headers = {}) => {
  const token = await auth.currentUser?.getIdToken(true);
  return fetch(`${BASE_URL}${url}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      authorization: `token ${token}`,
      ...headers
    }
  });
};

export const makeGet = async (url, headers = {}) => {
  const token = await auth.currentUser?.getIdToken(true);
  return fetch(`${BASE_URL}${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `token ${token}`,
      ...headers
    }
  });
};

export const createAccount = (data) => {
  const url = 'account/create-account';
  return makePost(url, data);
};

export const getSearchKey = () => {
  const url = 'resources/search-key';
  return makeGet(url);
};

export const getDashboardMetrics = (orgId) => {
  const url = `dashboard/${orgId}/metrics`;
  return makeGet(url);
};
