import React from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell, Legend } from 'recharts';
import { useStoreState } from 'easy-peasy';

const ResourceType = ({ data }) => {
  const resourceTypes = useStoreState((state) => state.resourceTypes);
  const colors = ['#0683fe', '#34c759', '#ffcc00', '#ff6600'];
  return (
    <ResponsiveContainer width="100%" height="85%">
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="uploads">
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Legend
          formatter={(value, { payload }) =>
            resourceTypes.find((type) => type.id === payload.resourceType)?.name
          }
          align="left"
        />
        <Tooltip formatter={(uploads) => [uploads, 'Uploads']} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ResourceType;
