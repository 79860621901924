import { useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useStoreState } from 'easy-peasy';
import { toast } from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

import { uploadResource } from '../dashboard.service';
import Fields from './Fields';
import {
  prepsyId,
  prepsyParametersId,
  prepsyPassionForFlyingId,
  prepsyScoreIds,
  prepsyTotalScoreId
} from '../../../helpers/constant.helper';

const Upload = () => {
  const [searchParams] = useSearchParams();
  const resourceId = searchParams.get('id');
  const organisations = useStoreState((state) => state.organisations);
  const resourceTypes = useStoreState((state) => state.resourceTypes);
  const [orgResourceTypes, setOrgResourceTypes] = useState([]);
  const [resourceType, setResourceType] = useState('');
  const [preResourceData, setPrevResourceData] = useState();
  const [preFilled, setPreFilled] = useState(false);
  const [orgId, setOrgId] = useState('');
  const [identifierFields, setIdentifierFields] = useState([]);
  const [resourceFields, setResourceFields] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.promise(
      uploadResource(
        orgId,
        identifierFields.map((field) => ({
          id: field.id,
          value: field.value,
          fieldType: field.fieldType
        })),
        resourceFields
          .filter((field) => field.value)
          .map((field) => ({
            id: field.id,
            value: field.value,
            fieldType: field.fieldType
          })),
        resourceType,
        preResourceData
      ).then(() => {
        setOrgId('');
        setIdentifierFields([]);
        setResourceType([]);
      }),
      {
        loading: 'Uploading resource',
        success: 'Uploaded successfully'
      }
    );
  };

  const updateResourceFields = (id, value) => {
    setResourceFields((fields) => {
      return prepsyScoreIds.includes(id) || id === prepsyParametersId
        ? fields.map((field) =>
            field.id === prepsyTotalScoreId
              ? {
                  ...field,
                  value: fields.reduce(
                    (sum, field) =>
                      prepsyScoreIds.includes(field.id)
                        ? field.id === id
                          ? (Number(value) || 0) + sum
                          : (Number(field.value) || 0) + sum
                        : sum,
                    0
                  )
                }
              : field.id === id
              ? { ...field, value }
              : field
          )
        : fields.map((field) => (field.id === id ? { ...field, value } : field));
    });
    if (id === prepsyParametersId && value === '4 parameters') {
      updateResourceFields(prepsyPassionForFlyingId, '');
    }
  };

  const updateIdentifierFields = (id, value) => {
    setIdentifierFields((fields) =>
      fields.map((field) => (field.id === id ? { ...field, value } : field))
    );
  };

  const getResourceData = async () => {
    const db = getFirestore();
    const docRef = doc(db, 'resources', resourceId);
    const resourceData = await getDoc(docRef).then((doc) => ({ id: doc.id, ...doc.data() }));
    setOrgId(resourceData.org_id);
    setResourceType(resourceData.resource_type);
    setPrevResourceData(resourceData);
  };

  const initFields = () => {
    const selectedResource = resourceTypes.find((type) => type.id === resourceType);
    setResourceFields(selectedResource?.fields.filter((field) => !field.computed) || []);
    setIdentifierFields(selectedResource?.identifiers.filter((field) => !field.computed) || []);
  };

  useEffect(() => {
    if (resourceTypes && resourceId && !preResourceData) {
      getResourceData();
    }
  }, [resourceId, resourceTypes]);

  useEffect(() => {
    if (resourceType) {
      initFields();
    }
  }, [resourceType]);

  useEffect(() => {
    if (preResourceData && !preFilled && resourceFields?.length) {
      setPreFilled(true);
      preResourceData.resources.forEach((resource) => {
        updateResourceFields(resource.id, resource.value);
      });
      preResourceData.identifiers.forEach((identifier) => {
        updateIdentifierFields(identifier.id, identifier.value);
      });
    }
  }, [preResourceData, preFilled, resourceFields]);

  useEffect(() => {
    if (orgId) {
      setOrgResourceTypes(
        resourceTypes.filter((type) =>
          organisations.find((org) => org.id === orgId)?.resource_types.includes(type.id)
        )
      );
    }
  }, [orgId]);

  return (
    <form onSubmit={handleSubmit} className="my-4">
      <div className="fw-bold border-bottom">Organisation</div>
      <FormControl fullWidth style={{ maxWidth: '300px' }} size="small" className="my-4">
        <InputLabel id="organisation">Organisation</InputLabel>
        <Select
          labelId="organisation"
          id="organisation-select"
          label="Organisation"
          value={orgId}
          required
          onChange={(e) => setOrgId(e.target.value)}>
          {organisations?.map((org) => (
            <MenuItem key={org.id} value={org.id}>
              {org.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="fw-bold border-bottom">Resource</div>
      <div className="d-flex flex-column">
        <FormControl fullWidth style={{ maxWidth: '300px' }} className="my-4" size="small">
          <InputLabel id="resource-type">Resource type</InputLabel>
          <Select
            labelId="resource-type"
            id="file-type-select"
            label="Resource type"
            value={resourceType}
            required
            onChange={(e) => setResourceType(e.target.value)}>
            {orgResourceTypes?.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Fields
        fields={resourceFields.filter((field) => field.id !== prepsyTotalScoreId)}
        updateField={updateResourceFields}
      />
      {resourceType === prepsyId ? (
        <div>
          <TextField
            type="number"
            id="total"
            label="Total score"
            variant="outlined"
            className="my-2"
            size="small"
            fullWidth
            title={
              resourceFields.find((field) => field.id === prepsyParametersId)?.value ===
              '4 parameters'
                ? 'Total of 4 parameters (4-20)'
                : 'Total of 5 parameters (5-25)'
            }
            disabled={prepsyScoreIds.some(
              (scoreId) => resourceFields.find((field) => field.id === scoreId)?.value
            )}
            inputProps={{
              ...(resourceFields.find((field) => field.id === prepsyParametersId)?.value ===
              '4 parameters'
                ? { min: 4, max: 20 }
                : { min: 5, max: 25 }),
              step: 0.1
            }}
            value={resourceFields.find((field) => field.id === prepsyTotalScoreId)?.value || ''}
            onChange={(e) => updateResourceFields(prepsyTotalScoreId, e.target.value)}
            style={{ maxWidth: '300px' }}
          />
        </div>
      ) : null}
      {identifierFields && identifierFields.length ? (
        <div className="fw-bold border-bottom mt-4">Identifier</div>
      ) : null}
      <Fields fields={identifierFields} updateField={updateIdentifierFields} />
      <Button type="submit" sx={{ textTransform: 'none', marginTop: '20px' }} variant="contained">
        Upload Resource
      </Button>
    </form>
  );
};

export default Upload;
