import { Menu } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { auth } from '../../helpers/firebase.helper';
import logo from '../../assets/logo.png';

const Header = ({ setMenu }) => {
  const logout = () => {
    auth.signOut();
  };

  const DrawerHeader = styled('header')({
    height: '70px',
    backgroundColor: 'var(--light-gray)',
    position: 'sticky',
    top: '0px',
    zIndex: 999
  });

  return (
    <DrawerHeader className="row align-items-center">
      <div className="col-auto">
        <button type="button" className="btn" onClick={() => setMenu((s) => !s)}>
          <Menu />
        </button>
      </div>
      <div className="col">
        <img src={logo} style={{ maxWidth: '150px', width: '100%' }} />
      </div>
      <div className="col-auto">
        <div>{auth.currentUser.email}</div>
        <div onClick={logout} style={{ cursor: 'pointer' }}>
          Logout
        </div>
      </div>
    </DrawerHeader>
  );
};

export default Header;
