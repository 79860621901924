import {
  query,
  where,
  getDocs,
  collection,
  getFirestore,
  orderBy,
  limit,
  startAfter
} from 'firebase/firestore';

const db = getFirestore();
const pageSize = 10;

export const getOrgResources = async (orgIds) => {
  const resourcesQuery = query(
    collection(db, 'resources'),
    where('org_id', 'in', orgIds),
    orderBy('creation_timestamp', 'desc'),
    limit(pageSize)
  );
  const snapshot = await getDocs(resourcesQuery);
  const data = [];
  let lastDoc;
  snapshot?.forEach((doc) => {
    lastDoc = doc;
    data.push({
      ...doc.data(),
      id: doc.id
    });
  });
  return { data, lastDoc };
};

export const getAdditionalResources = async (orgIds, oldLastDoc) => {
  const resourcesQuery = query(
    collection(db, 'resources'),
    where('org_id', 'in', orgIds),
    orderBy('creation_timestamp', 'desc'),
    startAfter(oldLastDoc),
    limit(pageSize)
  );
  const snapshot = await getDocs(resourcesQuery);
  const data = [];
  let lastDoc;
  snapshot?.forEach((doc) => {
    lastDoc = doc;
    data.push({
      ...doc.data(),
      id: doc.id
    });
  });
  return { data, lastDoc, hasMore: snapshot.size >= pageSize };
};
