import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';

const ScoreDistribution = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="80%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5
        }}>
        <XAxis
          strokeOpacity={0}
          dataKey="score"
          tick={({ x, y, payload }) => (
            <text fontSize={12} x={x} y={y + 5} fill="#666" textAnchor="middle">
              {payload.value}
            </text>
          )}
        />
        <YAxis
          tick={({ x, y, payload }) => (
            <text fontSize={12} x={x - 10} y={y + 5} fill="#666" textAnchor="middle">
              {payload.value}
            </text>
          )}
          strokeOpacity={0}
          type="number"
          domain={[0, 'dataMax']}
        />
        <Tooltip />
        <Legend></Legend>
        <Line type="monotone" strokeWidth={2} dataKey="4 parameters" stroke="#0683fe" />
        <Line type="monotone" strokeWidth={2} dataKey="5 parameters" stroke="#34c759" />
        <CartesianGrid strokeDasharray="3 3" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ScoreDistribution;
