import React from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../helpers/firebase.helper';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'react-hot-toast';

const Login = () => {
  const login = (e) => {
    e.preventDefault();
    toast.loading('Signing in');
    signInWithEmailAndPassword(
      auth,
      e.currentTarget.elements.email.value,
      e.currentTarget.elements.password.value
    )
      .then((user) => {
        toast.dismiss();
        if (user.user.emailVerified) {
          toast.success('Signed in');
        } else {
          toast.error('Verify email to sign in');
        }
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(`Failed to sign in: ${err.message}`);
      });
  };
  return (
    <form
      onSubmit={login}
      className="h-100 d-flex flex-column align-items-center justify-content-center">
      <h1 className="fw-bold pb-4">Sign In</h1>
      <input
        type="email"
        name="email"
        size="small"
        className="form-control px-4 py-3 mb-3"
        placeholder="Email"
        autoComplete="email"
        style={{ maxWidth: '350px' }}
        required
      />
      <input
        type="password"
        name="password"
        size="small"
        className="form-control px-4 py-3 mb-1"
        placeholder="Password"
        style={{ maxWidth: '350px' }}
        autoComplete="current-password"
        required
      />
      {/* <Link to='/forgot-pass'>Forgot Password</Link> */}
      <button type="submit" className="btn btn-primary my-4" style={{ minWidth: '350px' }}>
        Sign In
      </button>
      <span className="fw-bold">
        Not Registered? <Link to="/register">Register Here</Link>
      </span>
    </form>
  );
};

export default Login;
