import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Backdrop } from '@mui/material';

import { auth } from './helpers/firebase.helper';
import RouteGuard from './components/shared/RouteGuard';
import Dashboard from './components/dashboard/Dashboard';
import Resources from './components/dashboard/resources/Resources';
import Upload from './components/dashboard/upload/Upload';
import Login from './components/login/Login';
import Register from './components/register/Register';
import Overview from './components/dashboard/overview/Overview';
import {
  getOrganisations,
  getPublicSearchKey,
  getResourceTypes,
  getUserData
} from './components/dashboard/dashboard.service';
import Profile from './components/dashboard/profile/Profile';

const App = () => {
  const user = useStoreState((state) => state.user);
  const organisations = useStoreState((state) => state.organisations);
  const setUser = useStoreActions((actions) => actions.setUser);
  const setOrganisations = useStoreActions((actions) => actions.setOrgs);
  const setUserData = useStoreActions((actions) => actions.setUserData);
  const setResourceTypes = useStoreActions((actions) => actions.setResourceTypes);
  const setSearchKey = useStoreActions((actions) => actions.setSearchKey);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        getUserData(user.uid).then((userData) => {
          getPublicSearchKey().then((key) => setSearchKey(key));
          setUserData(userData);
          getOrganisations(userData.organisations).then((orgs) => setOrganisations(orgs));
        });
      } else {
        setUser(null);
      }
    });
  }, []);

  useEffect(() => {
    if (organisations) {
      getResourceTypes([
        ...new Set([...organisations.map((org) => org.resource_types)].flat(1))
      ]).then((data) => setResourceTypes(data));
    }
  }, [organisations]);

  return (
    <>
      {user === undefined ? (
        <Backdrop open />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route
              path="/dashboard*"
              element={
                <RouteGuard
                  isAuthenticated={!!user && user.emailVerified}
                  element={<Dashboard />}
                  redirectTo="/login"
                />
              }>
              <Route path="overview" element={<Overview />} />
              <Route path="resources" element={<Resources />} />
              <Route path="upload-resource" element={<Upload />} />
              <Route path="profile" element={<Profile />} />
            </Route>
            <Route
              path="/login"
              element={
                <RouteGuard
                  isAuthenticated={!(user && user.emailVerified)}
                  element={<Login />}
                  redirectTo="/dashboard/resources"
                  replace
                />
              }
            />
            <Route path="/register" element={<Register />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
};

export default App;
