import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';

import { createAccount } from '../../helpers/api.helper';
import { toast } from 'react-hot-toast';

const Register = () => {
  const [verifyEmail, setVerifyEmail] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value?.toLowerCase();
    const name = e.target.elements.name.value;
    const password = e.target.elements.password.value;
    toast.loading('Registering...');
    createAccount({ email, name, password })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss();
        if (res.status === 'success') {
          toast.success('Registration success');
          const auth = getAuth();
          signInWithEmailAndPassword(auth, email, password).then(() => {
            sendEmailVerification(auth.currentUser, {
              url: 'https://app.airbaseline.com/dashboard/upload'
            }).then(() => {
              setVerifyEmail(true);
            });
          });
          e.target.reset();
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
      {verifyEmail ? (
        <div>
          Thanks for signing up! You should see a verification link in your email (it might be in
          your spam folder). Verify your email to proceed.
        </div>
      ) : (
        <>
          <h1 className="fw-bold pb-4">Register</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              className="form-control px-4 py-3 mb-3"
              placeholder="Name"
              style={{ maxWidth: '350px' }}
              required
            />
            <input
              type="email"
              name="email"
              className="form-control px-4 py-3 mb-3"
              placeholder="Email"
              style={{ maxWidth: '350px' }}
              required
            />
            <input
              type="password"
              name="password"
              className="form-control px-4 py-3 mb-1"
              placeholder="Password"
              style={{ maxWidth: '350px' }}
              required
            />
            <button type="submit" className="btn btn-primary my-4" style={{ minWidth: '350px' }}>
              Register
            </button>
          </form>
          <span className="fw-bold">
            Already Registered? <Link to="/login">Login Here</Link>
          </span>
        </>
      )}
    </div>
  );
};

export default Register;
