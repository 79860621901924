import { createStore, action } from 'easy-peasy';
import overview from './components/dashboard/overview/overview.store';

const store = createStore({
  user: undefined,
  userData: {},
  organisations: null,
  resourceTypes: null,
  resources: [],
  resourcesLastDoc: null,
  hasMoreResources: true,
  searchKey: null,
  overview,
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setUserData: action((state, payload) => {
    state.userData = payload;
  }),
  setOrgs: action((state, payload) => {
    state.organisations = payload;
  }),
  setResourceTypes: action((state, payload) => {
    state.resourceTypes = payload;
  }),
  setResources: action((state, payload) => {
    state.resources = payload;
  }),
  addAdditionalResources: action((state, payload) => {
    state.resources = [...state.resources, ...payload];
  }),
  setResourcesLastDoc: action((state, payload) => {
    state.resourcesLastDoc = payload;
  }),
  setHasMoreResources: action((state, payload) => {
    state.hasMoreResources = payload;
  }),
  setSearchKey: action((state, payload) => {
    state.searchKey = payload;
  }),
  selectResource: action((state, payload) => {
    state.resources = state.resources.map((resource) =>
      resource.id === payload.id ? { ...resource, selected: payload.selected } : resource
    );
  }),
  clearSelection: action((state) => {
    state.resources = state.resources.map((resource) => ({
      ...resource,
      selected: false
    }));
  })
});

export default store;
