export const fieldsEnum = {
  TEXT: 0,
  DROPDOWN: 1,
  URL: 2,
  FILE: 3,
  CHECKBOX: 4,
  NUMBER: 5,
  EMAIL: 6,
  DATE: 7,
  RADIO: 8,
  SWITCH: 9
};

export const prepsyId = 'IVNYQPU6VBUIvluqbB9y';
export const prepsyScoreIds = [
  'ef0cc5df-3bad-4c7e-8ebb-468210df5c33',
  '957f6076-f4f9-4aeb-a342-82928094fe73',
  '44852728-ba30-4b29-b2a9-a5b2ef5147fd',
  '31e70ea9-d57b-4346-8ae6-2af2e6df371d',
  '29d90a66-0d12-4d37-8a84-66caf0c3cdb7'
];
export const prepsyPassionForFlyingId = '29d90a66-0d12-4d37-8a84-66caf0c3cdb7';
export const prepsyIpipReportId = '20a3b366-e975-4b7b-b6c6-a1b037872299';
export const prepsyParametersId = 'fb7f0817-0db6-46b8-bc6d-d7b3080e5cca';
export const prepsyTotalScoreId = 'b7a020d2-1709-4821-9ecb-7a1e033a1e68';
