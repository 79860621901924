import { useStoreState, useStoreActions } from 'easy-peasy';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import { Box, Checkbox } from '@mui/material';

import {
  fieldsEnum,
  prepsyId,
  prepsyIpipReportId,
  prepsyTotalScoreId,
  prepsyParametersId
} from '../../../helpers/constant.helper';
import { getFilename } from '../../../helpers/util.helps';
import dayjs from 'dayjs';

const ResourceItem = ({ item, selectEnabled }) => {
  const organisations = useStoreState((state) => state.organisations);
  const user = useStoreState((state) => state.user);
  const resourceTypes = useStoreState((state) => state.resourceTypes);
  const selectResource = useStoreActions((actions) => actions.selectResource);

  const org = organisations.find((org) => org.id === item.org_id);
  const resourceType = resourceTypes?.find((type) => type.id === item.resource_type);
  item.resources?.forEach((resourceField, index) => {
    const { fieldType, name, primary, isShown } = resourceType
      ? resourceType.fields.find((field) => field.id === resourceField.id)
      : {};
    item.resources[index].fieldType = fieldType;
    item.resources[index].name = name;
    item.resources[index].primary = primary;
    item.resources[index].isShown = isShown;
  });
  item.identifiers?.forEach((identifierField, index) => {
    const { fieldType, name, primary } = resourceType
      ? resourceType.identifiers.find((field) => field.id === identifierField.id)
      : {};
    item.identifiers[index].fieldType = fieldType;
    item.identifiers[index].name = name;
    item.identifiers[index].primary = primary;
  });

  const renderField = (field) => {
    if ([fieldsEnum.EMAIL, fieldsEnum.TEXT, fieldsEnum.NUMBER].includes(field.fieldType)) {
      return (
        <div className="text-truncate" key={field.id}>
          {field.name}: {field.value}
        </div>
      );
    } else if (field.fieldType === fieldsEnum.FILE) {
      return (
        <a
          key={field.id}
          target="_blank"
          href={field.value}
          download={getFilename(field.value)}
          rel="noreferrer">
          {getFilename(field.value)}
        </a>
      );
    } else if (field.fieldType === fieldsEnum.DATE) {
      return (
        <div className="text-truncate" key={field.id}>
          {field.name}: {dayjs(field.value).format('DD/MM/YYYY')}
        </div>
      );
    }
  };

  const setSelection = () => {
    selectResource({ id: item.id, selected: !item.selected });
  };

  return (
    <div className="my-2 mt-4 d-flex align-items-start">
      {selectEnabled ? (
        <Checkbox
          value={item.selected || false}
          checked={item.selected || false}
          onChange={setSelection}
          style={{ width: '30px' }}
          className="col-1"
          size="small"
        />
      ) : null}
      <div className="row w-100">
        <div className="col text-truncate">
          <span className="fw-bold d-flex align-items-center">
            <Avatar
              sx={{ width: 35, height: 35, marginRight: 1, display: { xs: 'none', sm: 'flex' } }}
              alt={item.identifiers.find((field) => field.primary)?.value}>
              {item.identifiers.find((field) => field.primary)?.value[0]}
            </Avatar>
            <span className="text-truncate">
              {item.identifiers.find((field) => field.primary)?.value}
            </span>
          </span>
          <div className="ms-sm-5 text-truncate">
            {item.identifiers
              .filter((field) => !field.primary)
              .map((field) => (
                <span key={field.id}>{field.value}</span>
              ))}
          </div>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }} className="mt-1">
            <div className="fw-bold text-truncate">{resourceType?.name}</div>
            <div className="text-secondary">{org.name}</div>
          </Box>
        </div>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }} className="col">
          <div className="fw-bold">{resourceType?.name}</div>
          {item.resource_type === prepsyId &&
          item.resources.find((resource) => resource.id === prepsyTotalScoreId) ? (
            <div className="text-secondary">
              Interview sheet (
              {item.resources.find((resource) => resource.id === prepsyParametersId)?.value})
            </div>
          ) : null}
          {item.resource_type === prepsyId &&
          item.resources.find((resource) => resource.id === prepsyIpipReportId)?.value ? (
            <div className="text-secondary">IPIP NEO 120</div>
          ) : null}
          <div className="text-secondary">{org.name}</div>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }} className="col text-truncate">
          <div>{item.created_by_email}</div>
          {item.updated_timestamp ? (
            <div className="text-secondary">
              Updated at {dayjs(item.updated_timestamp).format('DD/MM/YYYY hh:mmA')}
            </div>
          ) : null}
          <div className="text-secondary">
            {dayjs(item.creation_timestamp).format('DD/MM/YYYY hh:mmA')}
          </div>
        </Box>
        <div className="col text-truncate">
          {item.resources.filter((field) => field.primary).map((resource) => renderField(resource))}
          {item.resources
            .filter((field) => !field.primary && field.isShown)
            .map((resource) => renderField(resource))}
          {item.created_by === user?.uid ? (
            <Link className="d-block" to={`/dashboard/upload-resource?id=${item.id}`}>
              Edit
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ResourceItem;
