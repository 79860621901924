/* eslint-disable no-undef */
// import { Search } from '@mui/icons-material';
// import { InputAdornment, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox } from 'react-instantsearch-hooks-web';
import 'instantsearch.css/themes/satellite.css';

import { getOrgResources } from './resources.service';
import AllResources from './AllResources';

const Resources = () => {
  const userData = useStoreState((state) => state.userData);
  const searchKey = useStoreState((state) => state.searchKey);
  const setResources = useStoreActions((actions) => actions.setResources);
  const setResourcesLastDoc = useStoreActions((actions) => actions.setResourcesLastDoc);
  const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, searchKey);

  useEffect(() => {
    if (userData?.organisations) {
      getOrgResources(userData?.organisations).then((res) => {
        setResources(res.data);
        setResourcesLastDoc(res.lastDoc);
      });
    }
  }, [userData]);

  return (
    <div className="my-4">
      <InstantSearch searchClient={searchClient} indexName="resources">
        <SearchBox />
        <AllResources orgIds={userData?.organisations} />
      </InstantSearch>
    </div>
  );
};

export default Resources;
